import React from "react";
import noVideo from "../video/video-unavailable.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

const Video = ({ srcVideo, srcImg, title,id}) => {

  const closeVideo = (videoId) => {
    // console.log("cerrado");
    const elementVideo = document.getElementById(videoId);
    elementVideo.pause();
    elementVideo.currentTime = 0;
  }

  return (
    <>
      {/* <!-- Button trigger modal --> */}
      <button
        type="button"
        className="btn"
        data-bs-toggle="modal"
        data-bs-target={`#staticBackdrop${id}`}
      >
        <img src={srcImg} className="img-fluid radius-1" alt="dog training" />
      </button>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id={`staticBackdrop${id}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby={`staticBackdropLabel${id}`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content bg-dark">
            <div className="modal-header">
              <h5 className="modal-title text-white" id={`staticBackdropLabel${id}`}>
                {title}
              </h5>

              <FontAwesomeIcon
                icon={faWindowClose}
                className="video-close"
                data-bs-dismiss="modal"
                onClick={()=>closeVideo(`video_${id}`)}
              />
            </div>
            <div className="modal-body">
              <div className="ratio ratio-16x9">
                <video width="640" controls id={`video_${id}`}>
                  <source src={srcVideo} type="video/mp4" />
                  <img
                    src={noVideo}
                    className="img-fluid"
                    alt="Video not supported"
                  />
                  Your browser does not support multimedia content.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;

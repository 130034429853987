import React from "react";
import logo from "../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp,faWhatsappSquare,faFacebookSquare} from '@fortawesome/free-brands-svg-icons';



const Footer = () => {
  return (
    <footer class="container-fluid text-white pt-2 mt-4" id="footer">
    
      <div
        className="
        row
        align-items-center
        justify-content-center
      "
      >
        {/* <!-- Logo  --> */}
        <div className="col-12 col-md-2 text-center">
          <img
            loading="lazy"
            src={logo}
            alt=""
            className="img-fluid mb-2"
            style={{ width: "80px", height: "80px" }}
          />
        </div>

        {/* <!-- Conctactanos --> */}
        <div className="col-12 col-md-8 text-center">
          <p className="">
            <span className="me-3">
              <FontAwesomeIcon icon={faMapMarkerAlt} />  Costa Rica,Guanacaste, Nosara.
            </span>

            <span className="me-3">
              <FontAwesomeIcon icon={faWhatsapp} /> (+506) 8994 5086
            </span>

            <span>
              <FontAwesomeIcon icon={faEnvelope}/> latigok9cr@gmail.com
            </span>
          </p>
        </div>

        <div className="col-12 col-md-2 text-center">
          <a
            href="https://www.facebook.com/LatigoK9"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white me-3 btn m-0 p-0"
          >
            <FontAwesomeIcon icon={faFacebookSquare} className="h1" />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=50689945086&text=Welcome%20to%20LATIGO%20K9,%20how%20can%20we%20help%20you!"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white btn m-0 p-0"
          >
            <FontAwesomeIcon icon={faWhatsappSquare} className="h1" />
          </a>
        </div>
      </div>

      {/* <!-- Derechos --> */}
      <div className="bg-black text-white text-center">
        <p className="p-0 m-0">
          <small className="">
            Copyright © 2016 ~ {new Date().getFullYear()} All rights reserved |
            <em>
              <strong> LATIGO K9 S.A </strong>
            </em>{" "} | Website designed by
            <em>liwBH</em>
          </small>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

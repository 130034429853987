import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShippingFast } from "@fortawesome/free-solid-svg-icons";

const Spinner = () => {
  return (
    <div className="pt-5">
      <div className="sk-folding-cube">
        <div className="sk-cube1 sk-cube"></div>
        <div className="sk-cube2 sk-cube"></div>
        <div className="sk-cube4 sk-cube"></div>
        <div className="sk-cube3 sk-cube"></div>
      </div>

      <h3 className="text-center text-white pt-5"> 
        📧 Sending mail... <FontAwesomeIcon icon={faShippingFast} />
      </h3>
    </div>
  );
};

export default Spinner;

import React from 'react';
import question_1 from "../images/question-1.webp";
import question_2 from "../images/question-2.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";


const Questions = () => {
  return ( 
    <section
    className="container bg-secondary text-primary my-5 py-5 radius-2"
    id="question"
  >
    <h2 className="text-center h1 fw-bold">
      Frequent Questions
      <FontAwesomeIcon icon={faQuestionCircle} className="ms-3"/>
    </h2>

    {/* <!-- Preguntas #1 y #2 --> */}
    <div className="row mx-md-5 pb-2 my-5">
      <div className="col-12 col-md-6">
        <div className="accordion" id="contenedor_accordion1">
          {/* <!-- item1 --> */}
          <div className="accordion-item bg-dark">
            <div className="accordion-header" id="cabecera1">
              <button
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#contenido_colapsado1"
                aria-expanded="false"
                aria-controls="contenido_colapsado1"
              >
                <h5>How is the training?</h5>
              </button>
            </div>
            <div
              className="accordion-collapse collapse"
              aria-labelledby="cabecera1"
              data-bs-parent="#contenedor_accordion1"
              id="contenido_colapsado1"
            >
              <div className="accordion-body bg-dark text-light radius-b">
                <p className="lead">
                  We use positive reinforcement training on a daily basis,
                  without the use of rewards or treats where your pet will
                  learn to understand instructions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-6">
        <div className="accordion" id="contenedor_accordion2">
          {/* <!-- item2 --> */}
          <div className="accordion-item bg-dark">
            <div className="accordion-header" id="cabecera2">
              <button
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#contenido_colapsado2"
                aria-expanded="false"
                aria-controls="contenido_colapsado2"
              >
                <h5>What will your pet learn?</h5>
              </button>
            </div>
            <div
              className="accordion-collapse collapse"
              aria-labelledby="cabecera2"
              data-bs-parent="#contenedor_accordion2"
              id="contenido_colapsado2"
            >
              <div className="accordion-body bg-dark text-light radius-b">
                <p className="lead">
                  He will be able to understand and execute 8 instructions
                  through the commands used in his training, walk with his
                  owner correctly, correct bad behavior, behave with people,
                  interact with other dogs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- imagenes acordion #1 y #2 --> */}
    <div className="text-center pb-5">
      <img
        loading="lazy"
        src={question_1}
        alt=""
        className="img-fluid img-thumbnail border border-dark"
        style={{width: "600px", height: "400px"}}
      />
    </div>
    {/* <!-- Preguntas #3 y #4 --> */}
    <div className="row mx-md-5 pb-2 mb-5">
      <div className="col-12 col-md-6">
        <div className="accordion" id="contenedor_accordion3">
          {/* <!-- item3 --> */}
          <div className="accordion-item bg-dark">
            <div className="accordion-header" id="cabecera3">
              <button
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#contenido_colapsado3"
                aria-expanded="false"
                aria-controls="contenido_colapsado3"
              >
                <h5>What requirements should pets have?</h5>
              </button>
            </div>
            <div
              className="accordion-collapse collapse"
              aria-labelledby="cabecera3"
              data-bs-parent="#contenedor_accordion3"
              id="contenido_colapsado3"
            >
              <div className="accordion-body bg-dark text-light radius-b">
                <li className="lead">Up to date vaccinations.</li>

                <li className="lead">Being dewormed.</li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="accordion" id="contenedor_accordion4">
          {/* <!-- item4 --> */}
          <div className="accordion-item bg-dark">
            <div className="accordion-header" id="cabecera4">
              <button
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#contenido_colapsado4"
                aria-expanded="false"
                aria-controls="contenido_colapsado4"
              >
                <h5>What do you need to bring with your pet?</h5>
              </button>
            </div>
            <div
              className="accordion-collapse collapse"
              aria-labelledby="cabecera4"
              data-bs-parent="#contenedor_accordion4"
              id="contenido_colapsado4"
            >
              <div className="accordion-body bg-dark text-light radius-b">
                <li className="lead">A necklace.</li>
                <li className="lead">Your pet's food.</li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- imagenes acordion #3 y #4 --> */}
    <div className="text-center pb-5">
      <img
        loading="lazy"
        src={question_2}
        alt=""
        className="img-fluid img-thumbnail border border-dark"
        style={ {width: "600px", height: "400px"} }
      />
    </div>
    {/* <!-- Preguntas #5 y #6 --> */}
    <div className="row mx-md-5 pb-2 mb-5">
      <div className="col-12 col-md-6">
        <div className="accordion" id="contenedor_accordion5">
          {/* <!-- item5 --> */}
          <div className="accordion-item bg-dark">
            <div className="accordion-header" id="cabecera5">
              <button
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#contenido_colapsado5"
                aria-expanded="false"
                aria-controls="contenido_colapsado5"
              >
                <h5>Way to pay?</h5>
              </button>
            </div>
            <div
              className="accordion-collapse collapse"
              aria-labelledby="cabecera5"
              data-bs-parent="#contenedor_accordion5"
              id="contenido_colapsado5"
            >
              <div className="accordion-body bg-dark text-light radius-b">
                <p className="lead">
                  50% when entering your pet and the other 50% the day you
                  receive your pet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="accordion" id="contenedor_accordion6">
          {/* <!-- item6 --> */}
          <div className="accordion-item bg-dark">
            <div className="accordion-header" id="cabecera6">
              <button
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#contenido_colapsado6"
                aria-expanded="false"
                aria-controls="contenido_colapsado6"
              >
                <h5>How long can I host my pet?</h5>
              </button>
            </div>
            <div
              className="accordion-collapse collapse"
              aria-labelledby="cabecera6"
              data-bs-parent="#contenedor_accordion6"
              id="contenido_colapsado6"
            >
              <div className="accordion-body bg-dark text-light radius-b">
                <p className="lead">
                  We will take good care of your pet while you go on a trip,
                  we do not limit our service
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
   );
}
 
export default Questions;
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaw,
  faCalendarAlt,
  faCalendar,
  faHome,
  faShuttleVan,
  faBahai,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";

import service_1 from "../images/service-1.webp";
import service_2 from "../images/service-2.webp";

const Service = () => {
  return (
    <section
      className="container bg-dark text-white mt-5 radius-2"
      id="services"
    >
      <h2 className="text-center py-4 h1">
        Services
        <FontAwesomeIcon icon={faPaw} className="h1 ms-4" />
      </h2>

      <div className="row py-4 mx-md-5">
        <div className="col-12 col-md-6">
          <h3 className="">Individualized Obedience Training</h3>
          <hr />
          <p className="lead mt-5">
            LATIGO K9 offers both public and private obedience classNamees at
            our facility in Nosara, Costa Rica. We also offer obedience training
            for all types of dogs off-site and at home upon request.
          </p>
          <p className="lead">
            Obedience and agility form the foundation bond between handler and
            dog. We find the problem isn't always with the dog, it's in the
            communication of the handler. That's why our training focus is on
            working with the handler to give them the communication skills they
            need in order to work more effectively with their dog.
          </p>
        </div>

        <div className="col-12 col-md-6 text-center">
          <img
            loading="lazy"
            src={service_1}
            alt=""
            className="img-fluid img-thumbnail"
            style={{ width: "500px", height: "400px" }}
          />
        </div>
      </div>

      <div className="row m-md-5">
        <h3 className="">LATIGO K9 Obedience and Boarding Package</h3>
        <hr />

        <div className="col-12 col-md-6">
          <h4 className="mt-4">
            <FontAwesomeIcon icon={faCertificate} className="me-2" spin />
            BASIC TRAINING
          </h4>
          <p className="lead mt-3">
            In this two week intensive, your dog will learn the basic six
            commands; sit, stay, heal, lay down, come and no.
          </p>
          <p className="lead">
            This Package includes upon pick-up of your dog, a one-hour 'human
            training' session to get you and your family up to speed with your
            dog's new skill set.
          </p>
        </div>

        <div className="col-12 col-md-6">
          <h4 className="mt-4">
            <FontAwesomeIcon icon={faBahai} className="me-2" spin />
            ADVANCED TRAINING
          </h4>
          <p className="lead mt-3">
            Building upon our basic training, your dog will master more
            extensive commands along with off-lead obedience.
          </p>
          <p className="lead">Prerequisite ~ LATIGO K9 Basic Training</p>
        </div>
        <p className="my-3 text-center lead">
          Call us today at (+506) 8994 5086 to experience the difference of a
          LATIGO K9 trained dog.
        </p>
      </div>

      <div className="m-md-5">
        <h3 className="">
          Personalized Boarding
          <FontAwesomeIcon icon={faHome} className="ms-3" />
        </h3>
        <hr />
        <p className="lead mt-5">
          ​LATIGO K9 offers short and long term boarding at our 2 hectare
          training facility in Nosara, Costa Rica.
        </p>
        <p className="lead">
          Our knowledgable, on-site staff will care for your dog as if it were
          their own. While your dog will enjoy free play at designated times
          through out the day, you may opt for The LATIGO K9 Obedience and
          Boarding Package.
        </p>

        <div className="row py-4">
          <div className="col-12 col-md-6 text-center">
            <img
              loading="lazy"
              src={service_2}
              alt=""
              className="img-fluid img-thumbnail"
              style={{ width: "500px", height: "400px" }}
            />
          </div>

          <div className="col-12 col-md-6">
            <h4 className="mt-3">
              ​Short Term Boarding (under a month)
              <FontAwesomeIcon icon={faCalendar} className="ms-2" />
            </h4>

            <table
              className="
                table table-dark table-striped
                text-white
                mt-3
                border-1 border-white
              "
            >
              <thead>
                <tr>
                  <th scope="col">PACKAGE</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-secondary">
                  <td>​Boarding Only</td>
                </tr>

                <tr className="text-secondary">
                  <td>Obedience Only</td>
                </tr>

                <tr className="text-secondary">
                  <td>Boarding and Obedience</td>
                </tr>
              </tbody>
            </table>

            <h4 className="mt-5">
              Long Term Boarding (over a month)
              <FontAwesomeIcon icon={faCalendarAlt} className="ms-2" />
            </h4>
            <p className="text-secondary lead">
              Discounted prices may apply for long term boarders.
            </p>
          </div>
        </div>

        <p className="text-center lead mt-2 mb-5 text-light">
          Imagine life easier at your side ... why not come home with a fully
          trained pet?
        </p>
      </div>

      <div className="py-4 mx-md-5">
        <h3 className="">
          K9 Transport Service
          <FontAwesomeIcon icon={faShuttleVan} className="ms-3" />
        </h3>
        <hr />
        <p className="lead mt-5">
          Within Costa Rica, we offer pick-up and delivery to/from our facility
          in Nosara. Shuttles are air-conditioned, licensed and insured. For the
          safety of the driver and the K9, all dogs must be transported in a
          kennel. Contact us for pricing and reservations.
        </p>
      </div>
    </section>
  );
};

export default Service;

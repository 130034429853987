import React, { useState } from "react";
import Scramble from "react-scramble";

const Navegation = () => {
  /* Clases de componentes */
  let [menuClass, setMenuClass] = useState("");
  let [navbarSticky, setNavbarSticky] = useState(true);

  /* navbarSticky  */
  window.addEventListener("scroll", (event) => {
    let scrollValue = window.scrollY;
    let heightHome = document.getElementById("home").clientHeight;

    // console.log("Distancia de top ",scrollValue);
    // console.log("Ancho del componente ",heightHome);

    if (heightHome < scrollValue && navbarSticky) {
      //bg navbar con color
      setNavbarSticky(false);
      setMenuClass("bg-info stickyIn ");
    } else if (heightHome > scrollValue && !navbarSticky) {
      //bg navbar transparente
      setNavbarSticky(true);
      setMenuClass("stickyOut");
    }
  });

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark menu ${menuClass} gradient fixed-top`}
    >
      <div className="container">
        <a className="navbar-brand" href="/">
{/*           <h2 className="title">
            <Scramble
              autoStart
              text="Latigo k9"
              steps={[
                {
                  roll: 10,
                  action: "+",
                  type: "all",
                },
                {
                  action: "-",
                  type: "forward",
                },
              ]}
            />
          </h2> */}

          <Scramble 
            className="title-logo h2"
            autoStart
            text="Latigo k9"
            steps={[
              {
                roll: 10,
                action: "+",
                type: "all",
              },
              {
                action: "-",
                type: "forward",
              },
            ]}
          />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 fs-5">
            <li className="navbar-item px-3">
              <a href="#home" className="nav-link" aria-current="page">
                Home
              </a>
            </li>

            <li className="navbar-item px-3">
              <a href="#services" className="nav-link" aria-current="page">
                Services
              </a>
            </li>

            <li className="navbar-item px-3">
              <a href="#question" className="nav-link" aria-current="page">
                Questions
              </a>
            </li>

            <li className="navbar-item px-3">
              <a href="#gallery" className="nav-link" aria-current="page">
                Gallery
              </a>
            </li>

            <li className="navbar-item px-3">
              <a href="#about-us" className="nav-link" aria-current="page">
                About Us
              </a>
            </li>

            <li className="navbar-item px-3">
              <a href="#contact" className="nav-link" aria-current="page">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navegation;

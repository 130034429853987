import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImages,
  faWindowClose,
  faCaretRight,
  faCaretLeft,
} from "@fortawesome/free-solid-svg-icons";

import Masonry from "react-masonry-css";
import Video from "./video";

import img_1 from "../images/gallery-big/gallery-img-1.webp";
import img_2 from "../images/gallery-big/gallery-img-2.webp";
import img_3 from "../images/gallery-big/gallery-img-3.webp";
import img_4 from "../images/gallery-big/gallery-img-4.webp";
import img_5 from "../images/gallery-big/gallery-img-5.webp";
import img_6 from "../images/gallery-big/gallery-img-6.webp";
import img_7 from "../images/gallery-big/gallery-img-7.webp";
import img_8 from "../images/gallery-big/gallery-img-8.webp";
import img_9 from "../images/gallery-big/gallery-img-9.webp";
import img_10 from "../images/gallery-big/gallery-img-10.webp";
import img_11 from "../images/gallery-big/gallery-img-11.webp";
import img_12 from "../images/gallery-big/gallery-img-12.webp";
import img_13 from "../images/gallery-big/gallery-img-13.webp";
import img_14 from "../images/gallery-big/gallery-img-14.webp";
import img_15 from "../images/gallery-big/gallery-img-15.webp";
import img_16 from "../images/gallery-big/gallery-img-16.webp";
import img_17 from "../images/gallery-big/gallery-img-17.webp";
import img_18 from "../images/gallery-big/gallery-img-18.webp";
import img_19 from "../images/gallery-big/gallery-img-19.webp";

import video_1 from "../video/video-2.mp4";
import video_2 from "../video/video-2.mp4";
import video_3 from "../video/video-3.mp4";

import preVideo_1 from "../video/video2.png";
import preVideo_2 from "../video/video2.png";
import preVideo_3 from "../video/video3.png";
/* import noVideo from "../video/video-unavailable.jpg"; */

const Gallery = () => {
  const bigImageUrls = [
    { id: 0, src: img_1 },
    { id: 1, src: img_2 },
    { id: 2, src: img_3 },
    { id: 3, src: img_4 },
    { id: 4, src: img_5 },
    { id: 5, src: img_6 },
    { id: 6, src: img_7 },
    { id: 7, src: img_8 },
    { id: 8, src: img_9 },
    { id: 9, src: img_10 },
    { id: 10, src: img_11 },
    { id: 11, src: img_12 },
    { id: 12, src: img_13 },
    { id: 13, src: img_14 },
    { id: 14, src: img_15 },
    { id: 15, src: img_16 },
    { id: 16, src: img_17 },
    { id: 17, src: img_18 },
    { id: 18, src: img_19 },
  ];

  const videoUrls = [
    {
      id: 1,
      srcVideo: video_1,
      srcImg: preVideo_1,
      title: "Mobility Training",
    },
    {
      id: 2,
      srcVideo: video_2,
      srcImg: preVideo_2,
      title: "Understanding Commands",
    },
    {
      id: 3,
      srcVideo: video_3,
      srcImg: preVideo_3,
      title: "training Exercise",
    },
  ];

  const [model, setModel] = useState(false);
  const [temImgSrc, setTemImgSrc] = useState("");
  const [indexImg, setIndexImg] = useState(0);

  const getImagen = (image) => {
    setTemImgSrc(image.src);
    setIndexImg(image.id);
    setModel(true);
    // console.log(indexImg);
  };

  const closeImagen = () => {
    setModel(false);
    setIndexImg(0);

    setTimeout(() => {
      setTemImgSrc("");
    }, 500);
  };

  const nextImagen = () => {
    let num = indexImg + 1;

    if (num > bigImageUrls.length - 1) {
      num = 0;
    }
    setIndexImg(num);
    setTemImgSrc(bigImageUrls[indexImg].src);
    // console.log(indexImg);
  };

  const previousImagen = () => {
    let num = indexImg - 1;

    if (num < 0) {
      num = bigImageUrls.length - 1;
    }
    setIndexImg(num);
    setTemImgSrc(bigImageUrls[indexImg].src);
    // console.log(indexImg);
  };

  const breakpointColumnsObj = {
    default: 3,
    991: 2,
    441: 1
  };

  return (
    <section class="container bg-dark my-5 py-5 radius-2" id="gallery">
      <h2 class="text-center text-white h1">
        Gallery
        <FontAwesomeIcon icon={faImages} className="ms-3" />
      </h2>

      <div className={model ? "model open" : "model"}>
        <img src={temImgSrc} alt="Image Big" />

        <FontAwesomeIcon
          icon={faWindowClose}
          className="icon-close"
          onClick={() => closeImagen()}
        />
        <FontAwesomeIcon
          icon={faCaretRight}
          className="icon-next"
          onClick={() => nextImagen()}
        />
        <FontAwesomeIcon
          icon={faCaretLeft}
          className="icon-previous"
          onClick={() => previousImagen()}
        />
      </div>

      {/* Images */}
      <div className="container-gallery">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {bigImageUrls.map((image, index) => {
            return (
              <div
                className="pics"
                key={index}
                onClick={() => getImagen(image)}
              >
                <img
                  src={image.src}
                  alt={`Gallery item #${index + 1}`}
                  className="img-fluid img-thumbnail"
                />
              </div>
            );
          })}
        </Masonry>
      </div>

      {/* videos */}
      <hr className="bg-white border border-3 mt-5" />
      {/* <!-- contenedor de videos --> */}
      <div className="row text-center mt-5">
        {/* <!-- video 1 --> */}

        {videoUrls.map((video) => (
          <div className="col-12 col-md-4 mb-2 mb-md-0" key={video.id}>
            <Video
              srcVideo={video.srcVideo}
              srcImg={video.srcImg}
              title={video.title}
              id={video.id}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Gallery;

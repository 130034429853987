import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog  } from '@fortawesome/free-solid-svg-icons';


const InfoHome = () => {
  return ( 
    <div className="container bg-light opacity-2 radius-3 p-5 my-5 shadow-1">
    <h1 className="text-center my-2">
      <FontAwesomeIcon icon={ faDog } className="me-2"/>
      Obedience Training and Boarding for Dogs
    </h1>
    <p className="lead fs-4">
      In Latigo k9 we consider the pet as a faithful companion that may need
      help to improve its behavior and that requires a caregiver when you have
      to go on a trip, this motivates us to offer our services.
    </p>
  </div>
   );
}
 
export default InfoHome;
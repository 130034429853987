import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarkerAlt,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faWhatsappSquare,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import Spinner from "./Spinner";
/* google analytics */
import ReactGA from 'react-ga';

const Contact = () => {
  /* Clases de componentes */
  let [animationClass, setAnimationClass] = useState("");

  //  animacion de botones de contacto
  window.addEventListener("scroll", (event) => {
    let scrollValue = window.scrollY;
    let heightContact = document.getElementById("contact").offsetTop;

    // console.log("Distancia de top ",scrollValue);
    // console.log("Distancia de top componente ",heightContact);

    if (scrollValue > heightContact) {
      //agrega animacion
      setAnimationClass("animate__animated animate__heartBeat");
    } else {
      setAnimationClass("");
    }
  });

  /* javascript de formulario */
  const [dataForm, setDataForm] = useState({
    email: "",
    nombre: "",
    asunto: "",
    mensaje: "",
  });

  const [classForm, setClassForm] = useState({
    class_email: "",
    class_nombre: "",
    class_asunto: "",
    class_mensaje: "",
    class_boton: "",
  });

  const [loading, setLoading] = useState(false);

  const {
    class_email,
    class_nombre,
    class_asunto,
    class_mensaje,
    class_boton,
  } = classForm;
  const { email, nombre, asunto, mensaje } = dataForm;

  //recuperar datos de los input
  const handleOnChange = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnBlur = (e) => {
    let entrada = e.target.name;
    let flag = false;

    if (dataForm[entrada] !== "") {
      if (
        entrada === "email" &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(dataForm.email)
      ) {
        flag = false;
      } else {
        flag = true;
      }
    }

    if (flag) {
      setClassForm({
        ...classForm,
        [`class_${e.target.name}`]: "is-valid",
      });
    } else {
      setClassForm({
        ...classForm,
        [`class_${e.target.name}`]: "is-invalid",
      });
    }
  };

  //metodos de envio de formulario
  const handleSubmit = async (e) => {
    e.preventDefault();

    //en paquetado de datos
    const formulario = document.getElementById("formularioContacto");
    const data = new FormData(formulario);

    //validacion de envio de formulario
    if (
      class_email !== "is-valid" ||
      class_nombre !== "is-valid" ||
      class_asunto !== "is-valid" ||
      class_mensaje !== "is-valid"
    ) {
      setClassForm({
        ...classForm,
        class_boton: "is-invalid",
      });

      // console.log("formulario No enviado");
      return;
    }

    //activar el spinner
    setLoading(true);

    // console.log("formulario enviado ");
    const url = "https://still-escarpment-16964.herokuapp.com/send-email";

    await fetch(url, {
      method: "POST", //metodo de envio
      headers: {
        //encabezado
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(data).toString(), //convierte en cadena los datos
    });
    /* console.log(respuesta); */

    //evento google analytics
    ReactGA.event({
      category:'Form',
      action: 'Send form'
    });

    // limpiar formulario
    setDataForm({
      email: "",
      nombre: "",
      asunto: "",
      mensaje: "",
    });

    //desactivar el spinner
    setTimeout(() => {
      setLoading(false);
    }, 5000);

    //se indica formulario enviado
    setClassForm({
      ...classForm,
      class_boton: "is-valid",
    });

    //reseteo de alertas de formulario
    setTimeout(() => {
      setClassForm({
        class_email: "",
        class_nombre: "",
        class_asunto: "",
        class_mensaje: "",
        class_boton: "",
      });
    }, 1000);
  };

  //evitar auto completado y bots
  const handleOnCLick = (e) => {
    const inputName =  e.target.name;
    document.getElementById(inputName).removeAttribute("readOnly");
  }

  return (
    <section
      className="container bg-secondary text-primary bg-contact radius-2 my-5"
      id="contact"
    >
      <h2 className="pt-5 text-center h1 fw-bold text-dark">
        Contact
        <FontAwesomeIcon icon={faAddressBook} className="ms-5" />
      </h2>

      <div className="row py-5">
        <div className="col-12 col-md-12 col-lg-6">
          <div className="mx-0 mx-md-5 my-3 bg-dark text-white p-5 rounded-3 opacity-2">
            <h3 className="text-center">Contact Us</h3>
            {/* <!-- + info --> */}
            <div className="mt-3 text-center">
              <p className="">
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Costa Rica,
                Guanacaste, Nosara.
              </p>

              <p className="">
                <FontAwesomeIcon icon={faWhatsapp} /> (+506) 8994 5086
              </p>

              <p className="">
                <FontAwesomeIcon icon={faEnvelope} /> latigok9cr@gmail.com
              </p>

              <p className="my-5">
                <a
                  href="https://www.facebook.com/LatigoK9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-white me-3 btn m-0 p-0 ${animationClass}`}
                >
                  <FontAwesomeIcon icon={faFacebookSquare} className="h1" />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=50689945086&text=Welcome%20to%20LATIGO%20K9,%20how%20can%20we%20help%20you!"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-white btn m-0 p-0 ${animationClass}`}
                >
                  <FontAwesomeIcon icon={faWhatsappSquare} className="h1" />
                </a>
              </p>

              <p className="">​Exequiel Merlos - Owner and Lead Handler</p>
              <p>​Exequiel Merlos - Sales and Communications</p>
            </div>
            {/* <!-- - info --> */}
          </div>
        </div>

        <div className="col-12 col-md-12 col-lg-6">
          <div className="mx-0 mx-md-5 my-3 bg-dark text-white p-5 rounded-3 opacity-2">
            <h3 className="text-center">Send Message</h3>
            {loading ? (
              <Spinner />
            ) : (
              <form
                className="g-3"
                id="formularioContacto"
                onSubmit={handleSubmit}
                autoComplete="new-password"
              >
                {/* <!-- email --> */}
                <div className="">
                  <label htmlFor="email" className="form-label">
                    Email:
                  </label>
                  <input
                    type="email"
                    placeholder="Enter your email here."
                    name="email"
                    value={email}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onClick={handleOnCLick}
                    autoComplete="new-password"
                    className={`form-control ${class_email}`}
                    id="email"
                  />
                  <div className="valid-feedback">Very well!</div>
                  <div className="invalid-feedback">
                    You must enter a valid email.
                  </div>
                </div>
                {/* <!-- Nombre --> */}
                <div className="">
                  <label htmlFor="nombre" className="form-label">
                    Name:
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your full name here."
                    name="nombre"
                    value={nombre}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onClick={handleOnCLick}
                    autoComplete="new-password"
                    className={`form-control ${class_nombre}`}
                    id="nombre"
                  />
                  <div className="valid-feedback">Very well!</div>
                  <div className="invalid-feedback">
                    You must enter your name.
                  </div>
                </div>
                {/* <!-- asunto --> */}
                <div className="">
                  <label htmlFor="asunto" className="form-label">
                    Subject:
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your subject here."
                    name="asunto"
                    value={asunto}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onClick={handleOnCLick}
                    autoComplete="new-password"
                    className={`form-control ${class_asunto}`}
                    id="asunto"
                  />
                  <div className="valid-feedback">Very well!</div>
                  <div className="invalid-feedback">
                    You must enter a subject.
                  </div>
                </div>
                {/* <!-- Mensaje --> */}
                <div className="">
                  <label htmlFor="mensaje" className="form-label">
                    Message:
                  </label>
                  <textarea
                    style={{ resize: "none" }}
                    placeholder="Enter your message here."
                    name="mensaje"
                    value={mensaje}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    onClick={handleOnCLick}
                    readOnly
                    autoComplete="new-password"
                    className={`form-control ${class_mensaje}`}
                    id="mensaje"
                    rows="5"
                  ></textarea>
                  <div className="valid-feedback">Very well!</div>
                  <div className="invalid-feedback">
                    You must enter your message.
                  </div>
                </div>
                {/* <!-- boton --> */}
                <div className="text-center mt-3">
                  <button
                    type="submit"
                    className={`btn btn-secondary w-50 ${class_boton}`}
                    id="boton"
                  >
                    Send
                  </button>
                  <div className="valid-feedback">Mail sent successfully!</div>
                  <div className="invalid-feedback">
                    Error submitting the form.
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;

import React,{useEffect} from "react";
import Carousel from "./components/Carousel";
import Navegation from "./components/Navegation";
import InfoHome from "./components/InfoHome";
import Service from "./components/Services";
import Questions from "./components/Questions";
import Gallery from "./components/Gallery";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
/* google analytics */
import ReactGA from 'react-ga';


function App() {
  /* google analytics */
  useEffect(() => {
    ReactGA.initialize('UA-215991450-1');
    //reportar la pagina que se visita
    //ReactGA.pageview('/');
    ReactGA.pageview( window.location.pathname + window.location.search);
  }, [])

  return (
    <>
      <header>
        <Navegation />

        <div id="home">
          <Carousel />
        </div>
      </header>

      <main>
        <InfoHome />

        <Service />

        <Questions />

        <Gallery />

        <About />

        <Contact />
        
        <Footer/>

      </main>
    </>
  );
}

export default App;

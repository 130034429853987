import React from "react";
import item_1 from "../images/carousel/item_1.webp";
import item_2 from "../images/carousel/item_2.webp";
import item_3 from "../images/carousel/item_3.webp";
import item_4 from "../images/carousel/item_4.webp";
const Carousel = () => {
  return (
    <div id="SliderBv5" className="carousel slide" data-bs-ride="carousel">
      {/* <!-- indicadores --> */}
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#SliderBv5"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#SliderBv5"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#SliderBv5"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#SliderBv5"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
      </div>

      {/* <!-- items del carousel --> */}
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src={item_1}
            className=" d-block w-100"
            // style={ {width: "1000", height: "800"} }
            alt="Imagen #1"
          />
          <div className="carousel-caption d-none d-md-block">
            <p>They are part of our lives</p>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={item_2}
            className="d-block w-100"
            // style={ {width: "1000", height: "800"} }
            alt="Imagen #2"
          />
          <div className="carousel-caption d-none d-md-block">
            <p>Loyal companions</p>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={item_3}
            className=" d-block w-100"
            // style={ {width: "1000", height: "800"} }
            alt="Imagen #3"
          />
          <div className="carousel-caption d-none d-md-block">
            <p>Not only should they learn, so do we</p>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={item_4}
            className=" d-block w-100"
            // style={ {width: "1000", height: "800"} }
            alt="Imagen #4"
          />
          <div className="carousel-caption d-none d-md-block">
            <p>They can learn to behave better</p>
          </div>
        </div>
      </div>

      {/* <!-- flechas --> */}
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#SliderBv5"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#SliderBv5"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;

import React from "react";
import about_1 from "../images/about-us-1.webp";
import about_2 from "../images/about-us-2.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeanpub } from '@fortawesome/free-brands-svg-icons';


const About = () => {
  return (
    <section
      class="container bg-primary text-light my-5 radius-2"
      id="about-us"
    >
      <h2 class="pt-5 text-center">
        About Us
        <i class="fab fa-leanpub ms-3"></i>
        <FontAwesomeIcon icon={faLeanpub} className="ms-3"/>
      </h2>

      <div class="pb-5 pt-3 mx-md-5">
        {/* <!-- About us #1 --> */}
        <h3 class="p-3 text-center">
          Communication is key and LATIGO K9 training is where it all begins
        </h3>
        <div class="text-center pb-4">
          <img
            loading="lazy"
            src={about_1}
            alt=""
            class="img-fluid img-thumbnail"
            style={{ width: "600px", height: "400px"}}
          />
        </div>

        <p class="lead">
          The care and training we invest in pets determines the behavioral
          changes we want to make. At LATIGO K9 we pride ourselves on the
          capabilities of the pets we train, we build the foundation for
          communication, patience and the natural working capacity of each dog,
          thus creating a much more stable pet.
        </p>

        <p class="lead">
          There is no place for toys, jerks, and treats in our training. Our
          dogs work for guidance plain and simple. We focus on a stable family
          and companion pet that respects its owner's instructions.
        </p>

        {/* <!-- Abour us #2 --> */}
        <h3 class="p-3 text-center">
          How is the LATIGO K9 training method different?
        </h3>

        <div class="text-center pb-4">
          <img
            loading="lazy"
            src={about_2}
            alt=""
            class="img-fluid img-thumbnail"
            style={{ width: "600px", height: "400px"}}
          />
        </div>

        <p class="lead">
          Our training methods are different from 99% of what is out there
          because we do not believe that we are teaching our dogs anything new.
          We are simply communicating with our dogs to use their natural
          abilities for our benefit. We do this without the bribe of toys or
          treats. You'll see that there is no ego involved in what we do, which
          is a significant shift from saying "we teach our dogs to bite" or "we
          teach our dogs to track".
        </p>

        <p class="lead">
          By removing our egos and refraining from imposing human psychology on
          our dogs, we are free to work with them in an unlimited capacity.
        </p>

        <table
          class="
          table table-dark table-striped
          text-white
          mt-5
          border-1 border-white
          rounded-3
        "
        >
          <thead>
            <tr>
              <th scope="col">
                <h4>Latigo k9 stands out for:</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-light">
              <td>​ Have more than 12 years of experience.</td>
            </tr>

            <tr class="text-light">
              <td>Receive the recognition of all our clients.</td>
            </tr>

            <tr class="text-light">
              <td>Facilities that allow us to offer quality services.</td>
            </tr>

            <tr class="text-light">
              <td>
                Monitor the health of each pet and provide immediate care.
              </td>
            </tr>

            <tr class="text-light">
              <td>Personnel trained in the care of dogs.</td>
            </tr>

            <tr class="text-light">
              <td>Teach owners to communicate with their pets.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default About;
